.App {
  text-align: center;
}

body{
  background-color: #F4F4F4;
  overflow-x: hidden;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
   -khtml-user-select: none; 
     -moz-user-select: none; 
      -ms-user-select: none; 
          user-select: none; 
   
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

/* Cible toutes les barres de défilement */
::-webkit-scrollbar {
  width: 10px; /* Largeur de la barre de défilement */
}

/* Track/Axe de la barre de défilement */
::-webkit-scrollbar-track {
  background: #000000; /* Couleur de fond de l'axe */
}

/* Handle/Curseur de la barre de défilement */
::-webkit-scrollbar-thumb {
  background: #006285; /* Couleur du curseur */
  border-radius: 5px; /* Arrondit les coins du curseur */
}

/* En survolant le curseur */
::-webkit-scrollbar-thumb:hover {
  background: #003A54; /* Couleur du curseur au survol */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* METIERS */
.metier-sous-titres p{
  margin-bottom: 25px;
}
.metier-sous-titres p:nth-child(1){
  color: #00628B;
}
.metier-liste ul{
  list-style: none;
  list-style-position: inside;
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: left;
}
.metier-liste li{
  text-indent: -2em;
}
.metier-liste li::before{
  background-image: url('../images/puce.png'); /* remplace les puces par votre svg */
  content: ''; /* contenu vide */
  display: inline-block;
  height: 20px; /* hauteur de votre puce */
  width: 20px; /* largeur de votre puce */
  background-size: contain; /* assure que le svg s'adapte à la taille spécifiée ci-dessus sans déformation */
  background-repeat: no-repeat; 
  margin-right: 10px;
}

.metier-liste-engagement ul{
  list-style-type: "-   ";
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: left;
}
#metiers .react-parallax-bgimage {
  position: inherit !important;
  height: auto !important;
  width: 100% !important;
}

#headerVisuel .img-bottom img {
  bottom: 0 !important;
}


/* R & D */
.bg-projet{
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  transition: all 0.1s ease-in-out;
}

/* Contact */
.marqueur{
  cursor: pointer;
  fill: #00628B;
  transition: all 0.1s ease-in-out;
}
.marqueur:hover{
  fill: black;
}
.text-marqueur{
  font-size: 1em;
  font-weight: bold;
  fill: black;
  font-family: 'Montserrat', sans-serif;
  transition: all 0.1s ease-in-out;
}
.agence-OK{
  opacity : 0.5;
  fill: #BE1622;
}
.agence-NOK{
  opacity : 0.3;
  fill: #BE1622;
}
#headerVisuel .img-parallax {
  position: absolute !important;
}


@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.slide-down {
  animation: slide-down 0.5s ease-out;
}
  
@keyframes wave {
  0% {
    box-shadow: 0 0 0 0 rgba(66, 153, 225, 0.5);
  }
  70% {
    box-shadow: 0 0 0 30px rgba(66, 153, 225, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(66, 153, 225, 0);
  }
}
.animate-wave {
  animation: wave 2s infinite;
}



